input[type='radio'] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  background-color: #1d4854;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type='radio']:checked + label {
  color: #1d4854;
}
